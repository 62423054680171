import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import i18n from '@/i18n'
import 'font-awesome/css/font-awesome.min.css'
import { createPinia } from 'pinia'

const app = createApp(App)
app.use(createPinia()).use(i18n).use(router).use(ElementPlus, {
  locale: zhCn
}).mount('#app')
