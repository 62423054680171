import { defineStore } from 'pinia'
// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useUsersStore = defineStore('user', {
  state: () => {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      path: localStorage.getItem('path') || '/'
    }
  },
  getters: {
    getLang: (state) => {
      return () => state.lang
    },
    getPath: (state) => {
      return () => state.path
    }
  },
  actions: {
    setLang (lang: string) {
      this.lang = lang
      localStorage.setItem('lang', lang)
    },
    setPath (path: string) {
      this.path = path
      localStorage.setItem('path', path)
    }
  }
})
