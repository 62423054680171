import { createI18n } from 'vue-i18n'
import EN from './en'
import VI from './vi'

const messages = {
  en: {
    ...EN
  },
  vi: {
    ...VI
  }
}

const getCurrentLang = () => {
  let langCode = localStorage.getItem('lang') || ''
  if (!langCode) {
    const UALang = navigator.language
    langCode = 'en'
    if (UALang.indexOf('en') !== -1) {
      langCode = 'en'
    } else if (UALang.indexOf('vi') !== -1) {
      langCode = 'vi'
    }
    localStorage.setItem('lang', langCode)
  }
  return langCode
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getCurrentLang() || 'en',
  messages: messages
})

export default i18n
