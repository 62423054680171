import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/verity',
    name: 'verity',
    component: () => import('../views/verity/index.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
