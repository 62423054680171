<template>
<div class="main-contianer">
  <div class="main-head">
    <div class="header">
        <div class="head-left">
            <div class="logo-name">{{ $t('header.company') }}</div>
        </div>
        <div class="fanyi" v-if="currentLang === 'vi'"><img src="@/assets/fanyi.png" ></div>
        <div class="fanyi2" v-if="currentLang === 'en'"><img src="@/assets/fan1.png" ></div>
          <div class="drop-down-i18n">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ $t('header.lang') }}
                <el-icon size="large" class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="en" :disabled = "currentLang === 'en'">{{ $t('header.en') }}</el-dropdown-item>
                  <el-dropdown-item command="vi" :disabled = "currentLang === 'vi'">{{ $t('header.vi') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        <div class="head-right">
            <div class="left"><div @click="goPath(1)" :class="currenPath === '/' ? 'routepath active' : 'routepath'">{{ $t('header.indexPage') }}</div></div>
            <div class="left"><div @click="goPath(2)" :class="currenPath === '/verity' ? 'routepath active' : 'routepath'">{{ $t('header.VerityPage') }}</div></div>
            <div class="left"><div @click="goPath(3)" :class="currenPath === '/help' ? 'routepath active' : 'routepath'">{{ $t('header.HelpPage') }}</div></div>
        </div>
    </div>
  </div>
  <router-view/>
  <div class="main-footer">
    <div class="footer">
            <div class="company">{{ $t('header.version') }}</div>
    </div>
  </div>
</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ArrowDown } from '@element-plus/icons-vue'
import { useUsersStore } from '@/store/user'
import { computed, ref } from 'vue'
import router from '@/router'
const store = useUsersStore()
const i18n = useI18n()
const handleCommand = (command) => {
  i18n.locale.value = command
  store.setLang(command)
}

const path = ref('/')

const currentLang = computed(() => {
  return i18n.locale.value
})

const currenPath = computed(() => {
  return path.value
})

const goPath = (idx) => {
  if (parseInt(idx) === 2) {
    router.push({ path: '/verity', replace: true })
    path.value = '/verity'
  } else if (parseInt(idx) === 3) {
    router.push({ path: '/help', replace: true })
    path.value = '/help'
  } else {
    router.push({ path: '/', replace: true })
    path.value = '/'
  }
}

</script>
<style lang="scss">
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike,
strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td ,textarea,input {
  margin:0; padding:0;
}
.main-contianer {
  background: #fff;
}
.routepath{
  color: #252B42;
  display: inline-block;
  padding: 5px 10px;
}
.routepath.active {
  background: #FFDF00;
  color: #fff;
  border-radius: 5px;
}
.header{
    overflow: hidden;
    padding: 20px 50px;
    background: #FFFFFF;
    color: #000;
}
.head-left{
    float: left;
    display: flex;
}
.head-right{
    margin-top: 10px;
    float:right;
    display: flex;
    text-align: right;
}
.head-right .left{
  margin-left: 20px;
}
.logo-img img{
    width: 40px;
    margin-top: 10px;
    display: block;
}
.banner-bg img{
    display: block;
    width: 100%;
}
.logo-name{
    margin-left: 15px;
    margin-top: 15px;
    width: 650px;
}
.footer{
  background: #FFDF00;
  padding: 40px 120px;
  color: #fff;
  text-align: center;
  }
  .fanyi img{
    display: block;
    width: 30px;
    position: absolute;
    right: 600px;
    top:32px;
  }
  .fanyi2 img{
    display: block;
    width: 30px;
    position: absolute;
    right: 620px;
    top:33px;
  }
.drop-down-i18n {
  position: absolute;
  right: 500px;
  top:35px;
}
.contact-right{
  padding: 0 15px;
}
.el-dropdown {
   color: #000 !important;
   font-size: 18px !important;
}
</style>
