export default {
  header: {
    indexPage: 'Home',
    VerityPage: 'Privacy Policy',
    HelpPage: 'Common Problem',
    en: 'English',
    vi: 'Tiếng Việt',
    lang: 'Tiếng Việt',
    company: 'KOOKMIN BANK - HANOI BRANCH',
    version: 'Copyright © KOOKMIN BANK - HANOI BRANCH'
  },
  index: {
    bannerTitle: 'Multi-party cooperation Flexible option',
    bannerTip: 'Cooperate with a number of banks and other licensed financial institutions, a variety of services, a variety of options.',
    noticeTitle: 'Reviews from users',
    noticeTxt1: 'loan application, mobile loan in kind is worth it recommended, recommended by friends, pretty good. ',
    noticeTip1: 'by PartheniaSampler',
    noticeTxt2: 'Loans are fast and easy to borrow. Lending platform recommended by friends Lending platform, better score.',
    noticeTip2: 'by Eubanks Mona',
    noticeTxt3: 'This loan application is very easy to use and the loan speed is very fast. It is an easy to use personal loan application.',
    noticeTip3: 'by IsbellAdame',
    descTitle: '3 Ventajas',
    descTxt1: 'FAST',
    descTip1: 'We review and approve the loan in seconds.',
    descTxt2: 'SAFE',
    descTip2: 'We are registered and licensed under Vietnamese law.',
    descTxt3: 'NO WARRANTY',
    descTip3: 'Request your loan and receive your cash directly into your bank account.',
    infoTitle: 'KoMoney\'s commitment',
    infoDesc: 'Besides bringing professional, fast and easy consumer lending services (the main message of KoMoney) to consumers, we are committed to:',
    infoTxt1: 'CLIENT',
    infoTip1: 'FULLY meet the needs of customers through PROFESSIONAL customer service.',
    infoTxt2: 'INVESTORS',
    infoTip2: 'Continue to remain CONFIDENTIAL and EFFICIENT in the way  we run our business.',
    infoTxt3: 'PARTNER',
    infoTip3: 'Provide partners with POTENTIAL and EXACT business opportunities.',
    infoTxt4: 'STAFF',
    infoTip4: 'Build a FAIR and MOTIVATION working environment.',
    contact: 'CONTACT',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    support: 'Business Hours',
    addressVal: 'E6 Section, Cau Giay New Urban Area, Me Tri Ward, Floor 25, Ha Noi.',
    supportVal: '8am - 5pm (Monday-Friday)'
  },
  verity: {
    verity1: 'Scope of this statement',
    verity2: 'This Statement governs the manner in which KoMoney collects and processes customer Personal Information regarding individuals who use or otherwise interact with KoMoney’s products, websites or services (“you”). We encourage you to read this Statement carefully and to regularly check this page to review any changes we might make in accordance with the terms of this Statement.',
    verity3: 'Please note that this Statement does not apply to the processing by KoMoney of the Personal Information of KoMoney’s employees, contractors, temporary workers or other staff in connection with the roles they perform for KoMoney.',
    verity4: 'If you have additional questions you may contact our branch(es)/transaction office(s) or call us at 84-0892732251 for more information.',
    verity5: 'Types of Personal Information we collect',
    verity6: 'The types of Personal Information that may be collected by, or on behalf of, KoMoney under this Statement may vary by jurisdiction and applicable laws. The categories listed below are indicative but not exhaustive, and may change depending on your interactions with KoMoney. These categories include:',
    verity7: '1. Personal details: name; gender; photograph; date of birth; profession; business or employer; sector',
    verity8: '2. Contact details: email address; postal address; telephone number; fax number; customer ID',
    verity9: '3. Data relating to Sites or Apps: technical data (as outlined above, including device type, operating system, browser type, browser settings, IP address, language settings, dates and times of connecting to a Site, App usage statistics, App settings, dates and times of connecting to the App, location data, and other technical communications information); username; password; security login details; usage data etc.',
    verity10: '4. Commercial details/Customer’s transaction information: financial details and payment information (including bank details or credit card details provided in connection with a purchase); purchase history (including products, dates, locations and branches relating to any purchase); purchase interests; service requests; credit-related information and other information as regulated by applicable laws, jurisdiction;',
    verity11: '5. Marketing data: advertising interests; cookie data; clickstream data; browsing history; responses to direct marketing; and opt-outs from direct marketing.',
    verity12: 'How Personal Information is Collected and Collection Methods',
    verity13: 'KoMoney may collect Personal Information in the following circumstances:',
    verity14: 'Directly From You',
    verity15: '1. From our Sites:  KoMoney may collect or obtain personal information when you visit any KoMoney’s website (each a “Site”) or use any features or resources available on or through a Site. When you visit a Site, we collect information about your device and browser (such as device type, operating system, browser type, browser settings, IP address, language settings, dates and times of connecting to a Site and other technical communications information), all or some of which may constitute Personal Data.',
    verity16: '2. From Mobile Apps: KoMoney may collect or obtain personal information when you download or use a KoMoney’s mobile app (each an “App”). KoMoney Apps may record certain information (including App usage statistics, device type, operating system, App settings, IP address, language settings, dates and times of connecting to the App, location data, and other technical communications information) all or some of which may constitute personal information. An App used by KoMoney may have its own Privacy Policy, which you are encouraged to review before using the relevant App.',
    verity17: '3. From Services: KoMoney may collect or obtain personal information when you register to use any service provided via a Site or an App, or log into a Site or an App.',
    verity18: '4. From communications with you: KoMoney may collect or obtain personal information when you contact KoMoney (whether in person, by mail, by phone, online, via electronic communication or by any other means) including our customer surveys.',
    verity19: '5. From automated interactions or data collection technologies: KoMoney may collect information including IP address, referring URL, operating system, web browser and any other information automatically logged from the connection.',
    verity20: 'a. Cookies, flash cookies, pixel tags, web beacons, or other tracking technologies.',
    verity21: 'b.Third-party cookies, plug-ins, or social media connectors.',
    verity22: 'c. Any technology capable of tracking individual activity across devices or websites.',
    verity23: 'd.Location information or other meta-data provided by a device.',
    verity24: '6. Other Means: KoMoney may collect or obtain Personal Data when you interact with KoMoney via any other means.',
    verity25: '7. Social Media. Social Media Sites, third party data suppliers.',
    verity26: 'KoMoney may receive Personal Information about you from other third parties',
    verity27: 'For example:',
    verity28: '1. If you choose to interact with third party content or advertising on a Site or in an App, KoMoney may receive personal information about you from the relevant third party, in accordance with that third party’s applicable and legitimate privacy policy.',
    verity29: '2. If you choose to make an electronic payment directly to KoMoney, or through a Site or an App, KoMoney may receive personal information about you from third parties such as payment services providers, for the purposes of that payment.',
    verity30: '3. In complying with its obligations under applicable laws, KoMoney may receive personal information about you from law enforcement agencies and public authorities.',
    verity31: '4. KoMoney may receive personal information about you from public sources (such as telephone directories, advertisement/flyers, public information on websites, etc.).',
    verity32: 'Whenever you provide any such personal information, you must ensure that any affected third parties have consented to: (i) the disclosure of their personal information to KoMoney; and (ii) KoMoney’s further Processing of the personal information in accordance with this Statement. If you cannot obtain such consent, please refrain from communicating the personal information of third parties to KoMoney.',
    verity33: 'How Personal Information is Used and Shared',
    verity34: 'KoMoney may process your personal information for the following purposes:',
    verity35: '1. To provide requested goods or services;',
    verity36: '2. To send you information relevant to your past deals and interests, subject to compliance with Applicable Laws regarding direct marketing;',
    verity37: '3. Online behavioral advertising or interest-based advertising;',
    verity38: '4. To display the content of a Site, an App or a Device, and any customizations you may select;',
    verity39: '5. To monitor, adapt, update, secure and improve our products, services, Sites, Apps, Devices or our customer service;',
    verity40: '6. To verify your identity and ensure the security of your personal information;',
    verity41: '7. To respond to your service requests and support needs;',
    verity42: '8. To administer content, promotions, surveys or other features of a Site, an App, or a Device;',
    verity43: '9. To send you communications regarding administration of your accounts and the features of a Site, an App, or a Device;',
    verity44: '10. To notify you about changes to a Site, an App, or a Device;',
    verity45: '11. To perform internal data analyses and other Processing (including trend analysis and financial analysis);',
    verity46: '12. To give effect to your legal rights and your rights under this Statement;',
    verity47: '13. To protect against fraud, identity theft, and other unlawful activity;',
    verity48: '14. To establish or exercise KoMoney’s legal rights or defend legal claims;',
    verity49: '15. To establish or exercise legal rights, or defend legal claims;',
    verity50: '16. To comply with Applicable Laws, relevant industry standards and our other applicable policies;',
    verity51: '17. Any other purposes unique to our business operations; and',
    verity52: 'In any other way that we notify to you, either at the time of collection of your personal information or before the commencement of the relevant Processing or as otherwise required or permitted by applicable laws',
    verity53: 'Protection of your Personal Information',
    verity54: 'KoMoney is committed to processing your personal information securely.',
    verity55: 'We adopt appropriate data processing practices, and appropriate technical and organizational security measures, to protect against unauthorized access, read, use, alteration, disclosure, destruction or other processing of personal information. The Internet is not, in itself, a secure environment and we cannot provide an absolute assurance that your personal information transferred using the Internet will be secure at all times. Transmission of personal information over the Internet is at your own risk and you should only use secure systems to access Sites, Apps or Devices. You are responsible for keeping your access credentials for each Site, App and Device safe and confidential. You should notify KoMoney promptly if you become aware of any misuse of your access credentials, and immediately change your passwords.',
    verity56: 'Disclosure of your Personal Information',
    verity57: 'We will not sell, trade, or rent your personal information to others without obtaining your consent in accordance with Applicable Law. We may share or disclose your personal information to third parties in the following circumstances:',
    verity58: '1. We may share your personal information with employees and agents of KoMoney, for the purposes set out in this Statement.',
    verity59: '2. We may share your personal information with recipients within KoMoney, for the purposes of operating our business, operating Sites, Apps or Devices, providing you with products or services you have selected, or otherwise for the purposes set out in this Statement.',
    verity60: '3. We may share your personal information with recipients outside KoMoney, including partners, for the purposes of operating our business, operating Sites, Apps or Devices, providing you with products or services you have selected, or otherwise for the purposes set out in this Statement.',
    verity61: '4. We may use third party service providers to help us operate our business, operate Sites, Apps or Devices, provide you with products or services you have selected, or to administer activities on our behalf, such as sending out newsletters or surveys.',
    verity62: '5. We may share your personal information with such third parties for the purposes set out in this Statement. Our service providers are given the information they need to perform their designated functions, and we do not authorize them to use or disclose personal information for their own marketing or other purposes.',
    verity63: '6. We may share aggregate demographic information with our business partners, trusted affiliates and advertisers for the purposes outlined in this Statement.',
    verity64: 'We may also disclose your personal information if required by courts, government agencies and/or allowed by applicable laws or regulations.',
    verity65: 'While we make all reasonable efforts to ensure that such information is anonymized/masked, it is possible that your Personal Data may be disclosed in some force majeure circumstances.',
    verity66: 'Subject to providing prior notice and/or obtaining your consent as required under Applicable Law, we may share your personal information with our third party marketing partners, for direct marketing purposes. You may withdraw your consent at any time, or object at any time to the Processing of your Personal Information for direct marketing purposes in accordance with Applicable Law and we will apply your marketing preferences going forward.',
    verity67: 'If you make a payment via a Site or an App, or directly to KoMoney, your personal information may need to be provided to third parties (e.g., payment services providers) for the purposes of facilitating those payments.',
    verity68: 'In the event of a proposed or completed sale, merger or transfer all or a portion of our business or assets (including in the event of a reorganization, dissolution, liquidation or other restructuring or change in control), we may disclose your personal information to the prospective seller or buyer, or their representatives, provided that we have first taken reasonable, lawful steps to ensure the security and confidentiality of your personal information.',
    verity69: 'Third party services',
    verity70: 'You may encounter advertising or other content on any Site, App or Device that may link to websites or services of KoMoney’s partners, advertisers, sponsors, or other third parties.',
    verity71: 'We do not control the content or links that appear on these third party websites or services, and we are not responsible for the practices employed by third party websites or services linked to or from any Site, App or Device.',
    verity72: 'These websites and services may be subject to their own privacy policies and terms of use.',
    verity73: 'Direct Marketing',
    verity74: 'As set out in this Statement and in accordance with requirements under Applicable Law, we, our direct marketing partners, or our service providers, may contact you via email, text message, or other electronic means, from time to time, to provide you with information relating to products or services that may be of interest to you.',
    verity75: 'If you wish to unsubscribe from these communications, we include detailed unsubscribe instructions in each such communication. Alternatively, you may use the unsubscribe features provided through our Sites and Apps. Please note that, in the event that you unsubscribe from receiving communications relating to marketing or advertising, we may nevertheless continue to contact you for the purposes of carrying out your instructions, enabling you to make any purchases you request, sending you transactional messages, administration of your account, or as required or permitted by Applicable Laws.',
    verity76: 'Cookies',
    verity77: 'When you use or visit our websites, we may place one or more cookies on your device. A “cookie” is a small file that is placed on your device when you visit a website. It records information about your device, your browser and, in some cases, your preferences and browsing habits. We may use this information to recognize you when you return to our websites, to provide personalized services on our websites, to compile analytics in order to better understand website activity, and to improve our websites. You can use your browser settings to remove or block cookies on your device. However, if you decide not to accept, or to block, cookies from our websites, you may not be able to take advantage of all of the features of our websites.',
    verity78: 'We may process your personal information through cookie technology, in accordance with the provisions of this Statement. We may also use remarketing tags to serve advertisements to people who we know have previously visited our websites',
    verity79: 'To the extent that third parties have embedded content on our websites (e.g., social media features) those third parties may collect your personal information (e.g., cookie data) if you choose to engage with such third party content, or otherwise use third party services.',
    verity80: 'Data retention',
    verity81: 'We will only retain your personal information for as long as we require it in connection with the purposes set out in this Privacy Statement. We may also be required to retain your personal information for additional periods of time, for example, where required to do so by Applicable Laws.'

  },
  help: {
    help1: 'According to KoMoney\'s regulations, what are the conditions for customers to borrow consumer credit?',
    help2: 'Customers eligible for loans under KoMoney\'s regulations are individuals who meet all of the following conditions:',
    help3: '1. Being an individual with Vietnamese nationality and living and working in Vietnam;',
    help4: '2. Having full legal capacity and civil act capacity;',
    help5: '3. Customers with salary income from 3 million / month;',
    help6: '4. There is a feasible way to use capital;',
    help7: '5. No bad debt at KoMoney and other credit institutions;',
    help8: '6. Other conditions according to each loan product as prescribed by KoMoney from time to time.',
    help9: 'When I need a loan, what do I need to prepare to have a valid bank loan procedure?',
    help10: '1. Please contact KoMoney Customer Service for assistance;',
    help11: '2. Hotline: 84-0892732251',
    help12: '3 .Working time: From 8am to 6pm (Monday to Friday) and from 8am to 12pm (Saturday)',
    help13: 'How is the procedure for bank loan at KoMoney different from Bank and other financial companies?',
    help14: 'The loan procedure at KoMoney is much simpler than that of other banks and financial companies. When applying for a loan at KoMoney, you only need to provide at least the following documents:',
    help15: '1. Identity card / Citizen ID card.',
    help16: '2. Driving license.',
    help17: 'How many days before the due date need to be paid to avoid late payment?',
    help18: 'In case customers pay their loans through the banking system for KoMoney, in order to avoid late payment due to objective reasons, KoMoney always encourages customers to pay 5 working days in advance.',
    help19: 'If my loan is approved, how can I receive the money?',
    help20: 'Alternatively, receive disbursements by withdrawing cash at other ATMs or making transactions at NAPAS transaction points nationwide through a payment account and a debit card that is opened free of charge when you register as an application. loan loan.',
    help22: 'After signing the contract, I have questions about fees, insurance, interest rates or loan conditions, who should I contact for support?',
    help23: 'Please contact KoMoney Customer Service Department for information support and answers to questions:',
    help24: '1. Hotline: 84-0892732251',
    help25: 'Working time: From 8am to 6pm (Monday to Friday) and from 8am to 12pm (Saturday)',
    help26: 'How can I pay my loan at KoMoney?',
    help27: 'KoMoney supports many different payment methods so that you can pay on time in the most convenient, easy and safe way.',
    help28: 'You can pay your loan at KoMoney through the following methods:',
    help29: 'Payment method Payment location Payment information',
    help30: 'Payments',
    help31: 'Payment Location',
    help32: 'Billing Information',
    help33: 'Deposit/Transfer money to Devil Customer\'s Current Account number',
    help34: 'You can deposit/transfer money at all banks nationwide',
    help35: '1. Account Number Current Account Number (TKIT) of Customer Fund opened at KoMoney at the time of loan approval',
    help36: '2. Beneficiary account name. Name of the loan holder',
    help37: '3. Opened at KoMoney Ho Chi Minh',
    help38: 'Payoo',
    help39: 'You can pay at all payment points/stores associated with Payoo such as FPT Shop, FarnilyMart, Lotte Mart, VinMart, Circke K, Ministop... or via Payoo application.',
    help40: 'Pay via app or website',
    help41: '1. Select consumer loan payment function.',
    help42: '2. Select KoMoney icon',
    help43: '3. KoMoney.',
    help44: '4. Enter Loan Contract Number/ID card/CCCD.',
    help45: '5. Check the payment amount.',
    help46: '6. Payment confirmation.',
    help47: '7. Payment via cash through transaction points',
    help48: '8. Provide loan contract number/ID card/CCCD',
    help49: '10. Submit the amount you want to pay to the cashier',
    help50: 'Get a payment receipt and double check the information.',
    help51: 'Store receipts carefully',
    help52: 'Momo',
    help53: 'Devil Customers can pay at all payment points of goods associated with MoMo: F88, CoopMart, .. or via MoMo application.',
    help54: 'ShopeePay or Shopee',
    help55: 'You can pay through ShopeePay (AirPay) or Shopee',
    help56: 'Viettel Money',
    help57: 'You can pay at all transaction points/stores',
    help58: 'Keep receipts carefully. Viettel or via Viettel Money application (Viettel Pay).',
    help59: 'I have just thought of a job and am in the process of looking for a job, can I get a loan from KoMoney?',
    help60: 'The stability of customer\'s income is one of the factors to consider for a loan, so we encourage you to have a stable income first before applying for a loan with KoMoney to increase your chances of getting approved. Review loan applications.',
    help61: 'Will I have to pay additional costs such as application collection fees, appraisal fees?',
    help62: '1. You do not have to pay any costs when applying for a loan at KoMoney.',
    help63: 'Basis for determining payment date? Can I change the payment date from the specified payment date for some personal reason I cannot pay on the specified payment date of that month?',
    help64: '1. The payment date is determined based on the disbursement date, not the customer\'s salary receipt date.',
    help65: '2. You cannot change the monthly payment date after disbursement. If in case you cannot pay the payment of that month on the specified date, you can pay earlier, then the amount you pay will be paid on the due date of that period. .',
    help66: 'Can I request a copy of the Loan Agreement signed by an authorized representative and stamped with the bank\'s stamp?',
    help67: '1. Please call Hotline: 84-0892732251 to request a copy of the approved Loan Agreement signed by an authorized representative and stamped by KoMoney. Upon request, a copy of the loan contract will be mailed to you within 5-7 working days from the date of request.',
    help68: 'How much is the loan insurance fee?',
    help69: '1. According to current regulations, Loan insurance premium is calculated according to the formula = Amount of insurance * (1 year/12 month premium rate)* Number of months of insurance.',
    help70: 'In there:',
    help71: 'a. Insurance amount = 140% of the loan amount at the time of insurance participation (If the loan period is <= 24 months);',
    help72: 'b. Insurance amount = 110% of the loan amount at the time of insurance participation (If the loan period is > 24 months);',
    help73: 'c. One-year premium rate = 2.5%/year;',
    help74: 'For example:',
    help75: 'Customers who buy loan insurance on the amount of VND 10,000,000, the insurance period is 24 months, the insurance premium is calculated as follows:',
    help76: 'Premium = 10,000,000 * 140% *(2.5%/12)*24 = VND 700,000',
    help77: 'Customers who buy loan insurance on the amount of VND 10,000,000, the insurance period is 25 months, the insurance premium is calculated as follows:',
    help78: 'Premium = 10,000,000 VND *110% *(2.5%/12)*25 = 572,917 VND',
    help79: 'Will I be charged any fees for late payment?',
    help80: '1. In case you do not make payment or make incomplete payment on the Payment Date, you are obliged to provide a document committing the payment as prescribed in the Terms and Conditions of the loan product. and thereby allow KoMoney to implement risk management measures for the Borrower\'s loan.',
    help81: '2. If the Borrower fails to comply with the above request within a certain period of time from the date the loan is overdue (5-9 days depending on the product), the Borrower must bear a penalty and compensate for damage. according to KoMoney\'s regulations from time to time (100,000 - 250,000 VND) is collected at the time the Borrower commits the above violation. In addition, the Borrower is also obliged to compensate KoMoney for related losses.',
    help82: 'How to prepay the loan?',
    help83: '1. Please contact Hotline: 84-0892732251 for advice and instructions on procedures for prepayment of the loan.',
    help84: '2. The time of early payment will be the nearest monthly payment date after receiving the request for early payment and you complete the payment according to the instructions.',
    help85: '3. The amount of prepayment will be calculated and notified specifically by Customer Service when you contact us, including the remaining principal amount, interest up to the time of early termination, early repayment fee , overdue principal amount (if any), overdue interest amount (if any) and fines (if any).'
  }
}
