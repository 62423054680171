export default {
  header: {
    indexPage: 'Trang chủ',
    VerityPage: 'Điều khoản sử dụng',
    HelpPage: 'Hỏi đáp',
    en: 'English',
    vi: 'Tiếng Việt',
    lang: 'English',
    company: 'NGÂN HÀNG KOOKMIN - CHI NHÁNH HÀ NỘI',
    version: 'Copyright © NGÂN HÀNG KOOKMIN - CHI NHÁNH HÀ NỘI'
  },
  index: {
    bannerTitle: 'hợp tác nhiều bên, linh hoạt lựa chọn',
    bannerTip: 'Hợp tác với một số tổ chức tài chính được cấp phép như ngân hàng, với nhiều dịch vụ và sự lựa chọn.',
    noticeTitle: 'Nhận xét từ người dùng',
    noticeTxt1: 'ứng dụng cho vay, hiện vật cho vay điện thoại di động đáng để giới thiệu, được bạn bè giới thiệu, khá tốt. ',
    noticeTip1: 'by PartheniaSampler',
    noticeTxt2: 'Vi vay rat nhanh va dung vay. Nền tảng cho vay được bạn bè giới thiệu Nền tảng cho vay,  điểm số tốt hơn.',
    noticeTip2: 'by Eubanks Mona',
    noticeTxt3: 'Ứng dụng cho vay này rất dễ sử dụng và tốc độ cho vay rất nhanh. Nó là một ứng dụng cho vay cá nhân dễ sử dụng. ',
    noticeTip3: 'by IsbellAdame',
    descTitle: '3 ưu thế lớn',
    descTxt1: 'Nhanh chóng',
    descTip1: 'Chúng tôi xem xét và phê duyệt các khoản vay trong vài giây.',
    descTxt2: 'An toàn',
    descTip2: 'Chúng tôi được đăng ký và cấp phép theo luật pháp Việt Nam.',
    descTxt3: 'Không có thế chấp',
    descTip3: 'Đăng ký khoản vay và gửi tiền mặt trực tiếp vào tài khoản ngân hàng của bạn.',
    infoTitle: 'Cam kết của KoMoney',
    infoDesc: 'Bên cạnh việc mang đến dịch vụ cho vay tiêu dùng chuyên nghiệp, nhanh chóng và dễ dàng (thông điệp chính của thương hiệu KoMoney) cho người tiêu dùng, chúng tôi cam kết với:',
    infoTxt1: 'Cam kết của KoMoney',
    infoTip1: 'Đáp ứng ĐẦY ĐỦ nhu cầu của khách hàng thông qua dịch vụ khách hàng CHUYÊN NGHIỆP.',
    infoTxt2: 'NHÀ ĐẦU TƯ',
    infoTip2: 'Tiếp tục duy trì sự TẬP TRUNG và HIỆU QUẢ trong cách chúng tôi điều hành doanh nghiệp của mình.',
    infoTxt3: 'ĐỐI TÁC',
    infoTip3: 'Cung cấp cho đối tác những cơ hội kinh doanh TIỀM NĂNG và CHÍNH XÁC.',
    infoTxt4: 'NHÂN VIÊN',
    infoTip4: 'Xây dựng môi trường làm việc CÔNG BẰNG và nhiều ĐỘNG LỰC.',
    contact: 'Liên hệ',
    phone: 'Điện thoại',
    email: 'E-mail',
    address: 'Địa chỉ',
    support: 'Giờ kinh doanh',
    addressVal: 'Tầng 25, Keangnam Hanoi Landmark Tower, khu E6, Khu đô thị mới Cầu Giấy, phường Mễ Trì, quận Nam Từ Liêm, Hà Nội.',
    supportVal: '8 giờ sáng - 17 giờ tối (Thứ Hai-Thứ Sáu) '
  },
  verity: {
    verity1: 'Phạm vi của Thông báo này',
    verity2: 'Thông báo này điều chỉnh cách thức mà KoMoney thu thập và xử lý Thông tin cá nhân của Quý khách sử dụng hoặc tương tác với các sản phẩm, trang tin điện tử hoặc dịch vụ của KoMoney (“Quý khách”). Ngân hàng khuyến khích Quý khách đọc kỹ thông báo này và thường xuyên kiểm tra trang tin điện tử để cập nhật bất kỳ thay đổi nào mà Ngân hàng có thể thực hiện theo các điều khoản của thông báo.',
    verity3: 'Thông báo này không áp dụng cho việc KoMoney xử lý Thông tin cá nhân của nhân viên, nhà thầu, nhân viên thời vụ hoặc các đối tượng nhân viên khác liên quan đến các vai trò mà họ thực hiện đối với KoMoney.',
    verity4: 'Quý khách cần giải đáp thắc mắc vui lòng liên hệ chi nhánh/phòng giao dịch KoMoney gần nhất hoặc gọi đến 84-0892732251 để được hướng dẫn cụ thể hơn.',
    verity5: 'Các loại thông tin cá nhân mà Ngân hàng thu thập',
    verity6: 'Các loại Thông tin Cá nhân có thể được thu thập bởi KoMoney hoặc đại diện theo Thông báo này có thể khác nhau tùy theo thẩm quyền và luật hiện hành. Các danh mục liệt kê dưới đây bao gồm nhưng không giới hạn và có thể thay đổi tùy thuộc vào tương tác của Quý khách với KoMoney. Các danh mục này bao gồm:',
    verity7: '1. Thông tin cá nhân: tên; giới tính; ảnh chụp; ngày sinh; nghề nghiệp; doanh nghiệp hoặc nhà tuyển dụng; lĩnh vực ngành nghề.',
    verity8: '2. Thông tin liên hệ: địa chỉ email; địa chỉ bưu điện; số điện thoại; số fax; ID khách hàng.',
    verity9: '3. Dữ liệu liên quan đến các trang tin điện tử hoặc ứng dụng: dữ liệu kỹ thuật (như đã nêu ở trên, bao gồm loại thiết bị, hệ điều hành, loại trình duyệt, cài đặt trình duyệt, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Trang tin điện tử, thống kê sử dụng ứng dụng, cài đặt ứng dụng, ngày và giờ kết nối với Ứng dụng, dữ liệu vị trí và thông tin liên lạc kỹ thuật khác); tên tài khoản; mật khẩu; chi tiết đăng nhập bảo mật; dữ liệu sử dụng, v.v.',
    verity10: '4. Chi tiết giao dịch/Thông tin giao dịch của Khách hàng: thông tin tài chính và thanh toán (bao gồm thông tin ngân hàng hoặc thẻ tín dụng được cung cấp liên quan đến việc mua hàng); lịch sử giao dịch (bao gồm sản phẩm, ngày tháng, địa điểm và chi nhánh liên quan đến bất kỳ giao dịch nào); các mối quan tâm đối với các loại hàng hóa, dịch vụ; các yêu cầu dịch vụ; các thông tin liên quan đến tín dụng và các thông tin khác được quy định trong các văn bản pháp luật;',
    verity11: '5. Dữ liệu tiếp thị: các mối quan tâm đối với quảng cáo; dữ liệu cookie; dữ liệu clickstream; lịch sử duyệt web; phản ứng với tiếp thị trực tiếp; và lựa chọn không tham gia tiếp thị trực tiếp.',
    verity12: 'Cách thức thu thập thông tin cá nhân và phương pháp thu thập',
    verity13: 'KoMoney có thể thu thập Thông tin Cá nhân trong các trường hợp sau:',
    verity14: 'Trực tiếp từ Quý khách',
    verity15: '1. Từ các Trang tin điện tử của Ngân hàng: KoMoney có thể thu thập thông tin cá nhân khi Quý khách truy cập bất kỳ trang tin điện tử nào của KoMoney hoặc sử dụng bất kỳ tính năng, tài nguyên nào có sẵn trên hoặc thông qua Trang tin điện tử. Khi Quý khách truy cập Trang tin điện tử, Ngân hàng thu thập thông tin về thiết bị và trình duyệt của Quý khách (chẳng hạn như loại thiết bị, hệ điều hành, loại trình duyệt, cài đặt trình duyệt, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Trang tin điện tử và các thông tin liên lạc kỹ thuật khác), tất cả hoặc một số thông tin này có thể cấu thành Thông tin cá nhân.',
    verity16: '2. Từ Ứng dụng Di động: KoMoney có thể thu thập thông tin cá nhân khi Quý khách tải xuống hoặc sử dụng ứng dụng dành cho thiết bị di động của KoMoney. Các ứng dụng này có thể ghi lại một số thông tin nhất định (bao gồm thống kê sử dụng Ứng dụng, loại thiết bị, hệ điều hành, cài đặt Ứng dụng, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Ứng dụng, dữ liệu vị trí và các thông tin liên lạc kỹ thuật khác) tất cả hoặc một số thông tin này có thể tạo thành thông tin cá nhân. Ứng dụng do KoMoney sử dụng có thể có Chính sách bảo mật riêng, Quý khách nên xem lại Chính sách này trước khi sử dụng Ứng dụng liên quan.',
    verity17: '3. Từ các Dịch vụ: KoMoney có thể thu thập thông tin cá nhân khi bạn đăng ký sử dụng bất kỳ dịch vụ nào được cung cấp qua Trang tin điện tử hoặc Ứng dụng hoặc đăng nhập vào Trang tin điện tử hoặc Ứng dụng.',
    verity18: '4. Từ các trao đổi, liên lạc với Quý khách: KoMoney có thể thu thập thông tin cá nhân khi Quý khách liên hệ với KoMoney (gặp trực tiếp, qua thư, điện thoại, trực tuyến, liên lạc điện tử hoặc bất kỳ phương tiện nào khác) bao gồm cả các cuộc khảo sát Quý khách của Ngân hàng.',
    verity19: '5. Từ các tương tác hoặc các công nghệ thu thập dữ liệu tự động: KoMoney có thể thu thập thông tin bao gồm địa chỉ IP, URL giới thiệu, hệ điều hành, trình duyệt tin điện tử và bất kỳ thông tin nào khác được ghi tự động từ kết nối:',
    verity20: 'a. Cookie, flash cookie, thẻ pixel, tin điện tử beacons, hoặc các công nghệ theo dõi khác.',
    verity21: 'b. Cookie, plug-in hoặc trình kết nối mạng xã hội của bên thứ ba.',
    verity22: 'c. Bất kỳ công nghệ nào có khả năng theo dõi hoạt động cá nhân trên các thiết bị hoặc trang tin điện tử.',
    verity23: 'd. Thông tin vị trí hoặc siêu dữ liệu khác được cung cấp bởi một thiết bị.',
    verity25: '6. Các phương tiện khác: KoMoney có thể thu thập hoặc lấy Dữ liệu Cá nhân khi bạn tương tác với KoMoney thông qua bất kỳ phương tiện nào khác.',
    verity26: '7. Mạng xã hội: Các Trang mạng xã hội, nhà cung cấp dữ liệu bên thứ ba.',
    verity27: 'KoMoney có thể nhận Thông tin cá nhân về Quý khách từ các bên thứ ba khác',
    verity28: 'Ví dụ',
    verity29: '1. Nếu Quý khách tương tác với nội dung hoặc quảng cáo của bên thứ ba trên Trang tin điện tử hoặc trong Ứng dụng, KoMoney có thể nhận được thông tin cá nhân của Quý khách từ bên thứ ba có liên quan, theo chính sách bảo mật hiện hành hợp pháp của bên thứ ba đó.',
    verity30: ' 2.Nếu Quý khách chọn thanh toán điện tử trực tiếp tới KoMoney hoặc thông qua Trang tin điện tử hoặc Ứng dụng, KoMoney có thể nhận được thông tin cá nhân của Quý khách từ các bên thứ ba, chẳng hạn như nhà cung cấp dịch vụ thanh toán, cho mục đích thanh toán đó.',
    verity31: '3.Để tuân thủ các nghĩa vụ của mình theo luật hiện hành, KoMoney có thể tiếp nhận thông tin cá nhân về Quý khách từ các cơ quan pháp luật và cơ quan công quyền theo quy định pháp luật.',
    verity32: '4.KoMoney có thể tiếp nhận được thông tin cá nhân về Quý khách từ các nguồn công khai (như danh bạ điện thoại, thông tin quảng cáo/tờ rơi, các thông tin được công khai trên các trang tin điện tử, v.v.). ',
    verity33: 'Bất cứ khi nào thu thập thông tin cá nhân như vậy, KoMoney sẽ đảm bảo rằng các bên thứ ba có liên quan đã có sự đồng ý của Quý khách đối với: (i) việc cung cấp thông tin cá nhân của bên thứ ba cho KoMoney; và (ii) việc xử lý dữ liệu cho các mục đích của KoMoney trong Thông báo này. Nếu Quý khách không đồng ý, vui lòng không cung cấp thông tin cá nhân của bên thứ ba.',
    verity34: 'Mục đích sử dụng và chia sẻ thông tin cá nhân của Quý khách',
    verity35: 'KoMoney có thể xử lý và chia sẻ thông tin cá nhân của Quý khách cho các mục đích sau:',
    verity36: '1. Cung cấp sản phẩm hoặc dịch vụ được yêu cầu;',
    verity37: '2. Gửi cho Quý khách thông tin có liên quan đến giao dịch và nhu cầu của Quý khách, tuân theo Luật hiện hành về tiếp thị trực tiếp;',
    verity38: '3. Quảng cáo hành vi trực tuyến hoặc quảng cáo dựa trên nhu cầu, sở thích;',
    verity39: '4. Hiển thị nội dung của trang tin điện tử, ứng dụng, thiết bị và bất kỳ tùy chỉnh nào Quý khách có thể chọn;',
    verity40: '5. Giám sát, điều chỉnh, cập nhật, bảo mật và cải tiến các sản phẩm, dịch vụ, trang tin điện tử, ứng dụng, thiết bị hoặc dịch vụ Quý khách của Ngân hàng;',
    verity41: '6. Xác minh danh tính và đảm bảo tính bảo mật thông tin cá nhân của Quý khách;;',
    verity42: '7. Đáp ứng các yêu cầu dịch vụ và nhu cầu hỗ trợ của Quý khách;',
    verity43: '8. Quản lý nội dung, chương trình khuyến mãi, khảo sát hoặc các tính năng khác của trang tin điện tử, ứng dụng hoặc thiết bị;',
    verity44: '9.Gửi truyền thông về việc quản lý tài khoản của Quý khách và các tính năng của trang tin điện tử, ứng dụng hoặc thiết bị;',
    verity45: '10. Thông báo cho Quý khách về những thay đổi đối với trang tin điện tử, ứng dụng hoặc thiết bị;',
    verity46: '11.Thực hiện các phân tích dữ liệu nội bộ và các Xử lý khác (bao gồm cả phân tích xu hướng và phân tích tài chính);',
    verity47: '12.Thực hiện các quyền lợi hợp pháp của Quý khách theo Thông báo này;',
    verity48: '13. Bảo vệ khỏi gian lận, đánh cắp danh tính và các hoạt động bất hợp pháp khác;',
    verity49: '14. Để có cơ sở thiết lập, thực thi các quyền hợp pháp hoặc bảo vệ các khiếu nại pháp lý của KoMoney',
    verity50: '15. Để có cơ sở thiết lập, thực thi các quyền hợp pháp hoặc bảo vệ các khiếu nại pháp lý;',
    verity51: '16. Tuân thủ Luật hiện hành, các tiêu chuẩn ngành có liên quan và các chính sách hiện hành khác của Ngân hàng;',
    verity52: '17. Bất kỳ mục đích nào khác dành riêng cho hoạt động kinh doanh của Ngân hàng; và',
    verity53: '18 Theo bất kỳ cách thức nào khác mà Ngân hàng thông báo cho Quý khách, vào thời điểm thu thập thông tin cá nhân của Quý khách hoặc trước khi bắt đầu xử lý liên quan hoặc theo yêu cầu khác hoặc được luật hiện hành cho phép.',
    verity54: 'Bảo vệ thông tin cá nhân của Quý khách',
    verity55: 'KoMoney cam kết xử lý thông tin cá nhân của Quý khách một cách an toàn.',
    verity56: 'Ngân hàng áp dụng các phương pháp xử lý dữ liệu thích hợp cũng như các biện pháp bảo mật kỹ thuật và tổ chức thích hợp, để tránh việc truy cập, đọc, sử dụng, thay đổi, cung cấp, phá hủy trái phép hoặc các quá trình xử lý thông tin cá nhân khác. Internet không phải là một môi trường an toàn và Ngân hàng không thể đảm bảo tuyệt đối rằng thông tin cá nhân của Quý khách được chia sẻ bằng Internet sẽ luôn được bảo mật. Thông tin cá nhân được truyền tải khi sử dụng Internet là do Quý khách chịu trách nhiệm và Quý khách chỉ nên sử dụng các hệ thống an toàn để truy cập trang tin điện tử, ứng dụng hoặc thiết bị. Quý khách có trách nhiệm giữ thông tin xác thực truy cập của mình cho từng trang tin điện tử, ứng dụng hoặc thiết bị an toàn và bí mật. Quý khách cần thông báo ngay cho KoMoney nếu phát hiện có hành vi lạm dụng thông tin đăng nhập và thay đổi mật khẩu truy cập ngay lập tức.',
    verity57: 'Cung cấp thông tin cá nhân của Quý khách',
    verity58: 'Ngân hàng sẽ không bán, trao đổi, cho thuê (có thời hạn hoặc vô thời hạn) các thông tin cá nhân của Quý khách mà không có sự chấp thuận của Quý khách theo Luật hiện hành. Ngân hàng có thể chia sẻ hoặc cung cấp/sử dụng thông tin cá nhân của Quý khách cho các bên thứ ba trong các trường hợp sau:',
    verity59: '1. Ngân hàng có thể chia sẻ thông tin cá nhân của Quý khách với các nhân viên và đại lý của KoMoney cho các mục đích được nêu trong Thông báo này.',
    verity60: '2.Ngân hàng có thể chia sẻ thông tin cá nhân của Quý khách với những người nhận trong KoMoney, nhằm mục đích điều hành hoạt động kinh doanh, vận hành trang tin điện tử, ứng dụng hoặc thiết bị của Ngân hàng, cung cấp cho Quý khách các sản phẩm hoặc dịch vụ Quý khách đã chọn, hoặc cho các mục đích nêu trong Thông báo này.',
    verity61: '3. Ngân hàng có thể chia sẻ thông tin cá nhân của Quý khách với những người nhận bên ngoài KoMoney, bao gồm cả các đối tác, nhằm mục đích điều hành hoạt động kinh doanh của Ngân hàng, vận hành trang tin điện tử, ứng dụng hoặc thiết bị, cung cấp cho Quý khách các sản phẩm hoặc dịch vụ Quý khách đã chọn hoặc cho các mục đích nêu trong Thông báo này.',
    verity62: '4. Ngân hàng có thể sử dụng các nhà cung cấp dịch vụ bên thứ ba để giúp Ngân hàng vận hành nghiệp vụ, vận hành trang tin điện tử, ứng dụng hoặc thiết bị, cung cấp cho Quý khách các sản phẩm hoặc dịch vụ Quý khách đã chọn hoặc để quản lý các hoạt động thay mặt Ngân hàng, chẳng hạn như gửi bản tin hoặc khảo sát.',
    verity63: '5. Ngân hàng có thể chia sẻ thông tin cá nhân của Quý khách với các bên thứ ba như vậy cho các mục đích được nêu trong Thông báo này. Các nhà cung cấp dịch vụ của Ngân hàng được cung cấp thông tin cần thiết để thực hiện các chức năng được chỉ định và Ngân hàng không cho phép họ sử dụng hoặc cung cấp thông tin cá nhân cho mục đích tiếp thị của riêng họ hoặc các mục đích khác.',
    verity64: '6.Ngân hàng có thể chia sẻ thông tin nhân khẩu học tổng hợp với các đối tác kinh doanh, các chi nhánh đáng tin cậy và các nhà quảng cáo cho các mục đích được nêu trong Thông báo này.',
    verity65: 'Dịch vụ bên thứ ba',
    verity66: 'Quý khách có thể thấy quảng cáo hoặc nội dung khác trên bất kỳ trang tin điện tử, ứng dụng hoặc thiết bị nào có thể liên kết đến các trang tin điện tử hoặc dịch vụ của các đối tác, nhà quảng cáo, nhà tài trợ hoặc các bên thứ ba khác của KoMoney.',
    verity67: 'Ngân hàng không kiểm soát nội dung hoặc các liên kết xuất hiện trên các trang tin điện tử hoặc dịch vụ của bên thứ ba và ngân hàng không chịu trách nhiệm về các hoạt động được sử dụng bởi các trang tin điện tử hoặc dịch vụ của bên thứ ba được liên kết đến hoặc từ bất kỳ trang tin điện tử, ứng dụng hoặc thiết bị nào.',
    verity68: 'Các trang tin điện tử và dịch vụ này có thể tuân theo các chính sách bảo mật và điều khoản sử dụng của riêng của bên thứ ba.',
    verity69: 'Tiếp thị trực tiếp',
    verity70: 'Như được nêu trong Thông báo này và phù hợp với các yêu cầu của Luật hiện hành, Ngân hàng, các đối tác tiếp thị trực tiếp của ngân hàng hoặc các nhà cung cấp dịch vụ của ngân hàng, có thể liên hệ với Quý khách qua email, tin nhắn văn bản hoặc các phương tiện điện tử khác, bất cứ lúc nào, để cung cấp cho Quý khách thông tin liên quan đến các sản phẩm hoặc dịch vụ mà Quý khách có thể quan tâm.',
    verity71: 'Nếu Quý khách muốn hủy đăng ký nhận các thông báo này, ngân hàng đính kèm các chi tiết hướng dẫn hủy đăng ký trong mỗi thông báo. Ngoài ra, Quý khách có thể sử dụng các tính năng hủy đăng ký được cung cấp thông qua trang tin điện tử và ứng dụng của ngân hàng. Trong trường hợp Quý khách hủy đăng ký nhận thông tin liên quan đến tiếp thị hoặc quảng cáo, ngân hàng có thể tiếp tục liên hệ với Quý khách nhằm mục đích thực hiện theo chỉ dẫn của Quý khách, cho phép Quý khách thực hiện bất kỳ giao dịch mua nào Quý khách yêu cầu, gửi Quý khách tin nhắn giao dịch, quản trị tài khoản của Quý khách, hoặc theo yêu cầu hoặc cho phép của Luật hiện hành.',
    verity72: 'Cookies',
    verity73: 'Khi Quý khách sử dụng hoặc truy cập các trang tin điện tử của ngân hàng, Ngân hàng có thể đặt một hoặc nhiều cookie trên thiết bị của Quý khách. “Cookie” là một tệp nhỏ được đặt trên thiết bị của Quý khách khi Quý khách truy cập một trang tin điện tử. Nó ghi lại thông tin về thiết bị, trình duyệt của Quý khách và trong một số trường hợp, sở thích và thói quen duyệt tin điện tử của Quý khách. Ngân hàng có thể sử dụng thông tin này để nhận diện Quý khách khi Quý khách quay lại các trang tin điện tử của Ngân hàng, để cung cấp các dịch vụ được cá nhân hóa trên các trang tin điện tử của ngân hàng, để biên soạn số liệu phân tích nhằm hiểu rõ hơn về hoạt động của trang tin điện tử và để cải thiện các trang tin điện tử của Ngân hàng. Quý khách có thể sử dụng cài đặt trình duyệt của mình để xóa hoặc chặn cookie trên thiết bị của mình. Tuy nhiên, nếu Quý khách quyết định không chấp nhận hoặc chặn cookie từ các trang tin điện tử của Ngân hàng, Quý khách có thể không tận dụng hết tất cả các tính năng của các trang tin điện tử của Ngân hàng.',
    verity74: 'Ngân hàng có thể xử lý thông tin cá nhân của Quý khách thông qua công nghệ cookie, theo các quy định của Thông báo này. Ngân hàng cũng có thể sử dụng biện pháp tiếp thị lại để phân phát quảng cáo cho những cá nhân mà ngân hàng biết trước đây đã truy cập trang tin điện tử của mình.',
    verity75: 'Trong phạm vi các bên thứ ba đã gán nội dung lên trên các trang tin điện tử của Ngân hàng (ví dụ: các tính năng truyền thông xã hội), các bên thứ ba đó có thể thu thập thông tin cá nhân của Quý khách (ví dụ: dữ liệu cookie) nếu Quý khách chọn tương tác với nội dung của bên thứ ba đó hoặc sử dụng các dịch vụ của bên thứ ba.',
    verity76: 'Lưu trữ dữ liệu',
    verity77: 'Ngân hàng sẽ chỉ lưu trữ thông tin cá nhân của Quý khách trong trường hợp liên quan đến các mục đích được nêu trong Thông báo về Riêng tư dữ liệu này. Ngân hàng cũng có thể cần lưu trữ thông tin cá nhân của Quý khách trong một giai đoạn thời gian, chẳng hạn như khi Luật hiện hành yêu cầu.',
    verity78: '',
    verity79: '',
    verity80: '',
    verity81: ''
  },
  help: {
    help1: 'Theo quy định của KoMoney điều kiện để Khách hàng vay tín dụng tiêu dùng là gì ?',
    help2: 'Đối tượng khách hàng được vay vốn theo quy định của KoMoney là có cá nhân đáp ứng tất cả các điều kiện sau đây :',
    help3: '1. Là cá nhân có quốc tịch Việt Nam và sinh sống, làm việc tại Việt Nam;',
    help4: '2. Có năng lực phát luật, năng lực hành vi dân sự đầy đủ;',
    help5: '3. Khách hàng có mực thu nhập lương từ 3 triệu / tháng;',
    help6: '4. Có phương an sử dụng vốn khả thi;',
    help7: '5. Không có nợ xấu ở KoMoney và các Tổ chức tín dụng khác;',
    help8: '6. Các điều kiện khác theo từng cản phẩm vay theo quy định của KoMoney tuỳ theo từng thời kỳ.',
    help9: 'Khi có nhu cầu vay, tôi cần chuẩn bịn những gì để có thủ tục vay ngân hàng hợp lệ ?',
    help10: 'Quý khách vui lòng liên hệ Bộ phận Dịch vụ khách hàng KoMoney để được hỗ trợ ;',
    help11: '2. Hotline: 84-0892732251',
    help12: '3 .Thời gian làm việc : Từ 8h đến 18h ( từ thứ hai đến thứ sáu) và từ 8h đến 12h ( thứ bảy)',
    help13: 'Thu tục vay ngân hàng tại KoMoney có khác gì so với Ngân hàngvaf Công ty tài chính khác ?',
    help14: 'Thủ tục vay tại KoMoney đơn giản hơn rất nhiều so với Ngân hàng và Công ty tài chính khác. khi vay tại KoMoney Quy khách chỉ cần cung cấp tối thiểu các giấy tờ sau:',
    help15: '1. Chứng minh nhân dân / Căn cước công dân.',
    help16: '2. Giấy phép lái xe.',
    help17: 'Tối cần thanh toán trước ngày đến hạn bao nhiều ngày để tránh việc thanh toán trễ hạn?',
    help18: 'Trường hợp khách hàng thanh toán khoản vay của mình qua hệ thống Ngân hàng cho KoMoney, để tránh việc thanh toán trễ hạn vì những lú do khách quan, KoMoney luôn khuyến khích khách hàng thanh toán trước 5 ngày làm việc .',
    help19: 'Nếu khoản vay của tôi được chấp nhận thì tôi có thể nhận tiền bằng cách nào ?',
    help20: 'Quy khác nhận tiền giải ngân bằng cách rút tiền mặt tại khác ATM hoặc thực hiện giao dịch tại các điểm giao dịch của NAPAS trên toàn quốc thông qua tài khoản thanh toán và thẻ ghi nợ được mở miễn phí khi Quý khách đăng ký làm hồ sơ vay.',
    help22: 'Sau khi ký hợp đồng, tôi có thắc mắc về các khoản phí, bảo hiểm, lãi suất hoặc điều kiện khoản vay thì liên hệ ai để được hỗ trợ ?',
    help23: 'Quy khách vui lòng liên hệ Bộ phận Dịch vụ khách hàng KoMoney để được hỗ trợ thông tin và giải đáp các thắc mắc :',
    help24: '1. Hotline: 84-0892732251',
    help25: 'Thời gian làm việc : Từ 8h đến 18h ( từ thứ hai đến thứ sáu) và từ 8h đến 12h ( thứ bảy)',
    help26: 'Tôi có thể thanh toán khoản vay của mình tại KoMoney thông qua các hình thức nào ?',
    help27: 'KoMoney hỗ trợ nhiều phương thức thanh toán khác nhau để Quý khách có thể thanh toán đúng thời hạn một cách tiện lợi, dễ dàng và an toàn nhất.',
    help28: 'Quý khách có thể thanh toán khoản vay của mình tại KoMoney thông qua các hình thức sau :',
    help29: 'Hình thức thanh toán  Địa điểm thanh toán  Thông tin thanh toán',
    help30: 'Hình thức thanh toán',
    help31: 'Địa điểm thanh toán',
    help32: 'Thông tin thanh toán',
    help33: 'Nộp/ Chuyển tiền vào số Tài khoản thanh   toán (TKTT) của  Quỷ Khách',
    help34: 'Quý Khách có thể nộp/ chuyển tiền tại các   hệ thống Ngân hang trên toàn quốc',
    help35: '1. Số tài khoản Số Tài khoản thanh toán   (TKIT) của Quỹ Khách được mở tại KoMoney vào thời điểm khoản vay phê',
    help36: '2. Tên tài khoản thụ hưởng. Họ tên chủ khoản   vay',
    help37: '3. Mở tại KoMoney Hồ Chí Minh    ',
    help38: 'Payoo',
    help39: 'Quý Khách có thể thanh toán tại tất cả   các điểm thanh toán/ cửa hàng có liên kết với Payoo như FPT Shop,   FarnilyMart, Lotte Mart, VinMart, Circke K, Ministop... hoặc qua ứng dụng   Payoo.',
    help40: 'Thanh toán qua ứng dụng hoặc website',
    help41: '1. Lựa chọn chức năng thanh toán vay tiêu   dùng.',
    help42: '2. Chọn biểu tượng KoMoney',
    help43: '3. KoMoney.',
    help44: '4. Nhập Số hợp đồng vay/CMND/CCCD.',
    help45: '5. Kiểm tra số tiền thanh toán.',
    help46: '6. Xác nhận thanh toán.',
    help47: '7. Thanh toán qua tiền mặt qua điểm giao dịch',
    help48: '8. Cung cấp Số hợp đồng vay/CMND/CCCD',
    help49: '10. Nộp số tiền muốn đóng cho nhân viên thu   ngân',
    help50: '11. Nhận biên nhận thanh toán và kiểm tra lại   thông tin.',
    help51: '12. Lưu giữ biên nhận cẩn thận',
    help52: 'Momo',
    help53: 'Quỷ Khách có thể thanh toán tại tất cả   các điểm thanh toán của hàng có liên kết với MoMo: F88, CoopMart,.. hoặc qua ứng   dụng MoMo.',
    help54: 'ShopeePay hoặc Shopee',
    help55: 'Quý Khách có thể thanh toán thông qua qua   ứng dụng ShopeePay (AirPay) hoặc Shopee',
    help56: 'Viettel Money',
    help57: 'Quý Khách có thể thanh toán tại tất cả   các điểm giao dịch/ cửa hàng - Lưu giữ biên nhận cẩn thận. Viettel hoặc qua ứng   dụng Viettel Money (Viettel Pay).',
    help58: '',
    help59: 'Tôi vừa mới nghĩ việc và đang trong quá trình tìm việc thì có được vay vốn bên KoMoney hay không ?',
    help60: 'Sự ổn định thu nhập của khách hàng là một trong các yếu tố để xem xét cho vay, nên chúng tôi khuyến khích Quý khách nên có khoản thu nhập ổn định trước rồi mới tiền hành đăng ký vay với KoMoney để tăng khả năng được phê duyệt hồ sơ vay.',
    help61: 'Tôi có phải trả thêm chi phí như phí thu thập hồ sơ, phí thẩm định không?',
    help62: '1. Quý Khách không phải trả bất kì chi phí nào khi nộp hồ sơ vay tại KoMoney.',
    help63: 'Căn cứ xác định ngày thanh toán? Tôi có thể thay đổi ngày thanh toán so với ngày thanh toán theo quy định vì một số lý do cá nhân tôi không thể đóng tiền vào ngày thanh toán theo quy định của tháng đó?',
    help64: '1. Ngày thanh toán được xác định căn cứ vào ngày giải ngân, không căn cứ ngày nhận lương của Khách hàng.',
    help65: '2. Quý Khách không thể thay đổi ngày thanh toán hàng tháng sau khi giải ngân. Nếu trong trường hợp không thể nộp tiền thanh toán của tháng đó vào đúng ngày quy định, Quý Khách có thể thanh toán sớm hơn, lúc này số tiền Quý Khách nộp vào sẽ được thanh toán vào đúng ngày đến hạn thanh toán của kỳ đó.',
    help66: 'Tôi có thể yêu cầu cung cấp bản Hợp đồng vay có chữ ký của người đại diện có thẩm quyền và đóng dấu mộc ngân hàng hay không?',
    help67: '1. Quý khách vui lòng gọi đến Hotline: 84-0892732251 để yêu cầu được cung cấp bản sao Hợp đồng Vay đã được phê duyệt có chữ ký của người đại diện có thẩm quyền và đóng dấu của KoMoney. Sau khi yêu cầu, bản sao hợp đồng vay sẽ được gửi về đường bưu điện cho Quý khách trong vòng 5-7 ngày làm việc kể từ ngày yêu cầu.',
    help68: 'Phí bảo hiểm khoản vay là bao nhiêu?',
    help69: '1. Theo quy định hiện tại, Phí bảo hiểm khoản vay được tính theo công thức = Số tiền bảo hiểm * (Tỷ lệ phí bảo hiểm 1 năm/12 tháng)* Số tháng tham gia bảo hiểm.',
    help70: 'Trong đó:',
    help71: 'a. Số tiền bảo hiểm = 140% Số tiền vay tại thời điểm tham gia bảo hiểm (Nếu thời gian của khoản vay <= 24 tháng);',
    help72: 'b. Số tiền bảo hiểm = 110% Số tiền vay tại thời điểm tham gia bảo hiểm (Nếu thời gian của khoản vay > 24 tháng);',
    help73: 'c. Tỷ lệ phí bảo hiểm một năm = 2.5%/năm;',
    help74: 'Ví dụ:',
    help75: 'Khách hàng mua bảo hiểm khoản vay trên số tiền 10,000,000đ, thời gian bảo hiểm là 24 tháng thì tiền phí bảo hiểm được tính như sau: ',
    help76: 'Phí bảo hiểm = 10,000,000 * 140% *(2.5%/12)*24 = 700,000đ',
    help77: 'Khách hàng mua bảo hiểm khoản vay trên số tiền 10,000,000đ, thời gian bảo hiểm là 25 tháng thì tiền phí bảo hiểm được tính như sau:',
    help78: 'Phí bảo hiểm = 10,000,000đ *110% *(2.5%/12)*25 = 572,917đ',
    help79: 'Khi thanh toán trễhạn, tôi có phải bị phạt bất kỳ chi phí nào không?',
    help80: '1. Trong trường hợp Quý khách không thực hiện thanh toán hoặc thanh toán không đầy đủ vào Ngày thanh toán, Quý khách phải có nghĩa vụ cung cấp giấy tờ cam kết việc thanh toán theo quy định tại Điều khoản và Điều kiện của sản phẩm vay và theo đó cho phép KoMoney thực hiện các biện pháp quản lý rủi ro đối với khoản vay của Bên Vay.',
    help81: '2. Nếu Bên Vay không thực hiện yêu cầu trên đây trong 1 khoản thời gian nhất định kể từ ngày khoản vay bị quá hạn (5-9 ngày tuỳ theo từng sản phẩm), Bên Vay phải chịu một khoản phạt và bồi thường thiệt hại theo quy định của KoMoney trong từng thời kỳ (100.000 – 250.000 VNĐ) được thu tại thời điểm Bên Vay có hành vi vi phạm nêu trên. Ngoài ra, Bên Vay còn có nghĩa vụ bồi thường thêm cho KoMoney thiệt hại phát sinh có liên quan.',
    help82: 'Làm thế nào để tất toán trước hạn khoản vay?',
    help83: '1. Quý khách vui lòng liên hệ Hotline: 84-0892732251 để được tư vấn và hướng dẫn thủ tục tất toán trước hạn khoản vay.',
    help84: '2. Thời điểm tất toán trước hạn sẽ là ngày thanh toán hàng tháng gần nhất sau thời điểm nhận được yêu cầu tất toán trước hạn và Quý khách hoàn tất việc thanh toán theo hướng dẫn.',
    help85: '3. Số tiền trả nợ trước hạn sẽ được tính toán và thông báo cụ thể bởi bộ phận CSKH  khi Quý khách liên hệ, bao gồm số tiền gốc còn lại, tiền lãi tính đến thời điểm chấm dứt trước hạn, phí trả nợ trước hạn, số tiền gốc quá hạn (nếu có), số tiền lãi quá hạn (nếu có) và tiền phạt (nếu có).'
  }
}
